.modal-content {
    color: var(--wswc-red);
}

.modal-content .dropdown {
    margin: 1rem;
    width: 20vw;
}

.modal-content .fancy-button {
    height: 7vh;
    font-size: 2.5rem;
    margin: 1rem;
    border: solid 3px white;
}

.modal-content h2 {
    color: white;
}

.modal-content .dropdown span {
    color: white;
    font-size: 2.5rem;
    font-family: var(--wswc-font), serif;
}

.modal-content .dropdown select {
    font-size: 2.5rem;
    font-family: var(--wswc-font), serif;
}

.header-content {
    display: flex;
    justify-content: space-between;
}

.header-content a {
    color: white;
    font-size: xx-large;
}

.body-content {
    display: flex;
    column-gap: 1rem;
    height: 61vh;
}

.settings-view {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    background-color: var(--wswc-container);
    padding: 0 0.5rem 1rem 0.5rem;
    border-radius: 1rem;
    width: 60%;
}

.info-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--wswc-container);
    padding: 0 0.5rem 1rem 0.5rem;
    border-radius: 1rem;
    width: 40%;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.close-settings-button {
    margin: 0;
}

.close-settings .fancy-button {
    font-size: 48px;
    position: relative;
    bottom: 1rem;
    height: max-content;
}

.lobby-settings-link {
    text-align: center;
    font-size: 48px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    bottom: 12px;
    color: white;
    border-radius: 20px;
    padding: 0.5rem;
}

.large-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.large-modal-content {
    background-color: var(--wswc-red);
    border: none;
    width: 100%;
    height: 100%;
    max-width: 1600px;
    max-height: 90vh;
    padding: 20px;
    border-radius: 20px;
    overflow-y: auto;

    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
