.stamp {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stamp-image {
    height: auto;
    width: 50vw;
}

@media screen and (max-width: 600px) {
    .stamp-image {
        height: auto;
        width: 90vw;
    }
}

.stamp-text {
    width: 100%;
    background-color: var(--wswc-red);
    color: white;
    padding: 1.5rem;
    border: 4px solid white;
    border-radius: 20px;
    font-family: var(--wswc-font), sans-serif;
    margin: 0;
}
