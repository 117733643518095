.App {
    text-align: center;
}

h2 {
    font-family: var(--wswc-font), cursive;
    color: white;
    font-size: 96px;
    font-weight: normal;
    margin: 0;
}