.wrapped-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    transition: all 0.5s ease-in-out;
    justify-content: center;
}

.wrapped-stamp .stamp-image {
    width: 35vw;
    margin-left: 50rem;
    margin-top: 20rem;
    transform: rotate(-20deg);
    opacity: 0.85;
}

.wswc-title {
    max-width: 800px;
}

.wrapped-intro-buttons {
    display: flex;
    width: 81%;
    justify-content: space-between;
}

.wrapped-intro-buttons .fancy-button {
    display: flex;
    font-size: 40px;
    height: 4rem;
}

.moveable {
    position: fixed;
    top: 0;
    transition: top 1s ease-in-out;
}

.category-title {
    top: 35%;
}

.category-description {
    top: 60%;
    background-color: white;
    padding: 0.2rem 1rem;
    border-radius: 20px;
    border: var(--wswc-red) 4px solid;
}

.category-description p {
    color: var(--wswc-red);
    font-size: 36px;
}

.category-details {
    position: fixed;
    top: 75%;
}

.up {
    top: 5%;
}

.under-up {
    top: 22%;
}

.moveable .fancy-textbox, .category-details .fancy-textbox {
    width: initial;
    max-width: initial;
}

.stamp-image, .stamp-text {
    margin-top: 3rem;
}

.category-person .stamp-image {
    width: 400px;
    border-radius: 50px;
}

.wrapped-authors {
    display: flex;
    width: 100vw;
    justify-content: space-around;
}

.person {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    height: 75vh;
    justify-content: space-around;
    align-items: center;
}

.person p {
    color: var(--wswc-red);
    background-color: white;
    padding: 0.2rem 1rem;
    border-radius: 20px;
    border: var(--wswc-red) 4px solid;
}