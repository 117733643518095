.table-container {
    padding: 1rem;
    border-radius: 20px;
    border: 0.125rem solid var(--wswc-text);
    background-color: var(--wswc-border);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    max-width: 95%;
    max-height: 90vh;
    overflow-y: auto;
}

.player-table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    max-width: 1280px;
    background-color: var(--wswc-bg);
    color: var(--wswc-text);
    font-family: var(--wswc-font), cursive;
    border-radius: 20px;
    overflow: hidden;
    min-width: 640px;
}

.player-table th {
    padding: 1.5rem 0.6rem;
    text-align: center;
    background-color: var(--wswc-border);
    font-size: 2rem;
    border-bottom: 0.125rem solid var(--wswc-text);
    position: sticky;
    top: 0;
    z-index: 1;
    font-weight: bold;
}

.player-table td {
    padding: 1.5rem;
    text-align: center;
    font-size: 2rem;
    border-bottom: 1px solid var(--wswc-border);
    transition: background-color 0.2s;
}

.player-table tr:nth-child(even) {
    background-color: var(--wswc-bg-alt);
}

.player-table tr:first-child th:first-child {
    border-top-left-radius: 20px;
}

.player-table tr:first-child th:last-child {
    border-top-right-radius: 20px;
}

.player-table tr:last-child td:first-child {
    border-bottom-left-radius: 20px;
}

.player-table tr:last-child td:last-child {
    border-bottom-right-radius: 20px;
}
