.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.player-answer-count {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 15%;
    font-size: 3rem;
    -webkit-text-stroke: 2px white;
}

.borp .fancy-textbox {
    margin: 0 1rem 0 1rem;
}

.container .fancy-textbox {
    margin-top: 0;
}

.quote-container .fancy-textbox {
    font-size: 2rem;
}

.quote-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.borp .bob {
    position: relative;
    display: flex;
    justify-content: center;
}

.borp {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
}

.choices {
    display: flex;
}

.quote-image {
    max-height: 40vh;
    max-width: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
    border-radius: 20px;
    border: white 4px solid;
}