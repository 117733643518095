.modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--wswc-bg);
    padding: 20px 0;
    border: 2px solid var(--wswc-border);
    border-radius: 8px;
    width: 37rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-buttons {
    display: flex;
    justify-content: space-around;
    margin: 15px 0;
    width: 100%;
}

.modal-buttons .fancy-button {
    font-size: 32px;
    height: 75px;
}

.small-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.small-modal-content {
    background-color: var(--wswc-bg);
    border: 2px solid var(--wswc-border);
    border-radius: 8px;
    color: var(--wswc-text);
    max-width: 37rem;
    width: 80%;
    max-height: 80vh;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
