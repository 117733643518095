:root {
    /*fonts*/
    --wswc-font: "Nerko One", cursive;
    /*colors*/
    --wswc-red: #b20606;
    --wswc-container: rgba(193, 193, 193, 0.7);
    --wswc-bg: #a83232;
    --wswc-bg-alt: #8f2929;
    --wswc-border: #7b2424;
    --wswc-highlight: #d94444;
    --wswc-text: white;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url("../public/assets/img/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

p {
    text-align: center;
    font-size: 34px;
    font-weight: lighter;
    font-family: var(--wswc-font), serif;
    color: white;
    margin: 0;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

::-webkit-scrollbar {
    width: 15px
}

::-webkit-scrollbar-track {
    background-clip: padding-box;
    background-color: #71798d;
    border-bottom: 20rem solid transparent;
    border-top: 20rem solid transparent;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)
}

::-webkit-scrollbar-thumb {
    background-color: #F15405;
    background-image: -webkit-linear-gradient(45deg, hsla(0, 0%, 100%, .2) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .2) 0, hsla(0, 0%, 100%, .2) 75%, transparent 0, transparent);
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #ff8344
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}
