.balloon {
    transform: translateX(-50%);
    width: 15rem;
    height: 20rem;
    background: var(--wswc-red);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    color: white;
    padding: 10px;
    position: relative;
    animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.balloon img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
}

.balloon-cord {
    position: absolute;
    bottom: -150px; /* Moves the cord below the balloon */
    left: 50%;
    width: 50px;
    transform: translateX(-50%) scaleX(3);
}

/* Make the SVG path move like a real cord */
.wiggle {
    animation: wavePattern 3s ease-in-out infinite;
}

@keyframes wavePattern {
    0% {
        d: path("M5,0 Q10,20 5,40 T5,80 T5,120 T5,150");
    }
    50% {
        d: path("M5,0 Q0,20 5,40 T5,80 T5,120 T5,150");
    }
    100% {
        d: path("M5,0 Q10,20 5,40 T5,80 T5,120 T5,150");
    }
}

@keyframes floatZigZagLeft {
    0% {
        transform: translateX(-50vw) translateY(100vh);
    }
    25% {
        transform: translateX(-25vw) translateY(75vh);
    }
    50% {
        transform: translateX(-40%) translateY(50vh);
    }
    75% {
        transform: translateX(-15vw) translateY(25vh);
    }
    100% {
        transform: translateX(0) translateY(-10vh);
    }
}

@keyframes floatZigZagRight {
    0% {
        transform: translateX(50vw) translateY(100vh);
    }
    25% {
        transform: translateX(25vw) translateY(75vh);
    }
    50% {
        transform: translateX(40%) translateY(50vh);
    }
    75% {
        transform: translateX(15vw) translateY(25vh);
    }
    100% {
        transform: translateX(0) translateY(-10vh);
    }
}

@keyframes floatUpwards {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-120vh);
        opacity: 0;
    }
}

/* Assign the animation to balloons */
.from-left {
    animation: floatZigZagLeft ease-in-out forwards;
}

.from-right {
    animation: floatZigZagRight ease-in-out forwards;
}

.float-out {
    animation: floatUpwards 3s ease-in-out forwards;
}

.balloon-text {
    word-break: break-word;
    font-size: 4rem;
}