.connection-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;

    max-width: 60vw;
    height: 50vh;

    background-color: var(--wswc-red);
    padding: 1.5rem;
    border: 4px solid white;
    border-radius: 20px;
}

.bob {
    position: absolute;
    width: 100%;
    bottom: 100%;
}

.image-div {
    position: absolute;
    width: 100%;
    bottom: 100%;
}

.talking-stick {
    height: 7vh;
    width: auto;
}

.text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    text-align: center;
}

.text-container h2 {
    font-size: calc(3vw + 3vh);
    font-weight: lighter;
    font-family: var(--wswc-font), serif;
    color: white;
    margin: 0 0 2rem 0;
}

.text-container p {
    font-size: 30px;
    font-weight: lighter;
}