.lobby-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.code-and-player-container {
    display: flex;
    height: 80vh;
    position: relative;
    column-gap: 1rem;
}

.qr-wrapper {
    height: 50vw;
    max-height: 100%;
}

.judge-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: var(--wswc-red);
    width: 45vw;
    padding: 1.5rem;
    border: 4px solid white;
    border-radius: 20px;
    max-height: 675px;
    height: 46vw;
}

.lobby-link {
    text-align: center;
    font-size: 48px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000000;
    bottom: 12px;
    background-color: var(--wswc-red);
    border: 4px solid white;
    border-radius: 20px;
    padding: 0 0.3rem;
}

b {
    font-weight: lighter;
}

.judge-names {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-y: auto;
}

.player-name {
    margin: 10px;
    font-size: 2.5vw;
    background-color: #F15405;
    padding: 1rem;
    border-radius: 10px;
}

.bob-button {
    text-align: center;
    max-width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.sniffing-bob {
    max-height: 60px;
    width: 100px;
    object-fit: contain;
}

.bob-button .fancy-button {
    margin: 0.5rem 0 0 0;
}

/* Dropdown Styles */
.dropdown {
    margin-top: 1rem;
    color: var(--wswc-red);
    z-index: 2;
}

.dropdown select {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
}

.dropdown select:focus {
    outline: none;
    border-color: var(--wswc-red);
}

.icon-link-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.settings {
    font-size: 3.5rem;
    cursor: pointer;
    background-color: var(--wswc-red);
    border: 4px solid white;
    border-radius: 20px;
    transition: all 0.2s ease-in-out;
}

.settings:hover {
    transform: scale(1.05);
    background-color: var(--wswc-highlight);
}

.wswc-wrapped-container {
    position: relative;
    margin-right: 10rem;
    margin-top: 7.5rem;
    cursor: pointer;
    transition: transform 10s linear;
}

.wswc-wrapped-container:hover {
    transform: scale(10) translateX(-8.5rem) translateY(1rem);
}

@media screen and (max-width: 1000px) {
    .bob-button .fancy-button {
        height: inherit;
    }

    h2 {
        font-size: 4rem;
    }
}

@media screen and (max-width: 700px) {
    .lobby-container {
        row-gap: 0;
    }

    .code-and-player-container {
        flex-direction: column;
        align-items: center;
        column-gap: 1rem;
        justify-content: space-between;
    }

    .icon-link-container {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .lobby-link img {
        display: none;
    }
}
