.choice {
    font-family: var(--wswc-font), sans-serif;
    color: white;
    background-color: var(--wswc-red);
    padding: 0.5rem;
    align-content: center;
    margin: 1rem;

    border: 4px solid white;
    border-radius: 20px;

    height: auto;
    width: 13rem;
}

.choices {
    /*margin: 1rem*/
}
