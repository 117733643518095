.scoreboard-container {
    display: flex;
    align-items: center;
}

.borp .bob {
    position: relative;
    display: flex;
    justify-content: center;
}

.borp {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
}

.back-to-lobby-button .fancy-button {
    font-size: 48px;
    height: 10vh;
}