.wrapped-overview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1.5rem 3rem;
    background: var(--wswc-bg);
    border: 5px solid var(--wswc-border);
    border-radius: 25px;
    color: var(--wswc-text);
    max-width: 1100px;
    max-height: 800px;
    margin: 0 auto;
}

.overview-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
    width: 100%;
    max-width: 1000px;
}

.overview-card {
    background: var(--wswc-container);
    padding: 0.5rem;
    border-radius: 30px;
    border: 4px solid var(--wswc-border);
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.3);
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.overview-title {
    font-family: var(--wswc-font), cursive;
    font-size: 3rem;
    margin-bottom: 1rem;
    color: var(--wswc-red);
}

.overview-name {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--wswc-text);
    margin: 1rem 0;
}

.overview-score {
    font-size: 2rem;
    margin-top: 0.5rem;
    color: var(--wswc-highlight);
}

.overview-image {
    max-width: 65%;
    max-height: 100%;
    border-radius: 20px;
}

.overview-buttons {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
}

.overview-buttons .fancy-button {
    font-size: xxx-large;
    height: max-content;
    padding: 1rem 3rem;
}
