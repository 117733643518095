.custom-checkbox {
    display: flex;
    position: relative;
    cursor: pointer;
    font-size: 3rem;
    font-family: var(--wswc-font), serif;
    user-select: none;
    color: white;
    gap: 25px;
    margin: 1rem;
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    font-size: 3rem;
}

.checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    background-color: var(--wswc-red);
    border: 4px solid white;
    border-radius: 10px;
    box-shadow: 0 8px #880404;
    font-size: 2rem;
    color: white;
    transition: all 0.2s ease-in-out;
    text-shadow: 1px 2px 5px #500000;
}

.custom-checkbox input:checked + .checkmark {
    background-color: limegreen;
    box-shadow: 0 5px #065f06;
    transform: translateY(3px);
}

.custom-checkbox input:active + .checkmark {
    transform: scale(0.9);
}

.custom-checkbox.disabled .checkmark {
    background-color: dimgray;
    color: lightgray;
    border: 4px solid lightgray;
    box-shadow: none;
    text-shadow: none;
    cursor: not-allowed;
}
